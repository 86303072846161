import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  IconDefinition,
  faCheckCircle,
  faCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { IconListItem } from 'src/app/shared/models';
import { v4 as uuid } from 'uuid';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LoadingComponent } from '../loading/loading.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, LoadingComponent, NgFor, FaIconComponent],
})
export class AutocompleteComponent implements OnInit {
  faTimes = faTimes;
  faCheckCircle = faCheckCircle;
  faCircle = faCircle;

  id = uuid();
  term?: string;
  results?: any[];
  loading = false;

  showDropdown = false;

  fieldsMap: { [key: string]: string[] } = {
    doctors: ['firstName', 'lastName'],
    drugs: [
      'mmsl_code',
      'nda',
      'rxcui',
      'prescribable_synonym',
      'rxnav_str',
      'rxnorm_name',
      'rxnorm_synonym',
      'tallman_synonym',
    ],
    zip: ['zip_code', 'city'],
  };

  @Input() placeholder = 'Search';
  @Input() title?: string;
  @Input() icon?: IconDefinition;
  @Input() iconClass?: string;

  @Input() type: 'one' | 'multiselect' = 'one';
  @Input() index?: string;
  @Input() items?: IconListItem[];
  @Input() bindLabel?: string;
  @Input() bindValue?: string;

  @Input() hideSelected = false;
  @Input() showSelectAll = false;
  @Input() small = false;
  @Input() smallTitle = false;

  @Input() customResults = false;
  @Output() resultsChange = new EventEmitter();

  @Input() valueId?: any;
  @Output() valueIdChange = new EventEmitter();
  @Input() value?: any;
  @Output() valueChange = new EventEmitter();

  valueMap: any = {};

  @Input() lookupMap?: any;

  currentRequest = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.results = structuredClone(this.items);

    if (this.value) {
      if (this.type === 'one') {
        this.term = this.bindLabel
          ? this.value[this.bindLabel]
          : this.objectValuesString(this.value);
        this.valueMap[this.value.id] = true;
      } else {
        for (const item of this.value) {
          this.valueMap[item.id] = true;
        }
      }
    }
  }

  search() {
    if (this.term && this.items) {
      // this.loading = true;
      console.log('search', this.term, this.index);
      // this.searchService
      //   .autocomplete(
      //     this.term,
      //     this.index,
      //     [...this.fields, 'id'],
      //     this.locationBased
      //   )
      //   .then((res: any) => {
      //     if (request !== this.currentRequest) return;
      //     console.log('res', res);
      //     this.results = res?.suggestions;
      //     this.resultsChange.emit(this.results);
      //     this.loading = false;
      //     this.cdr.detectChanges();
      //   });
      const results = [];

      for (const item of this.items) {
        const value = this.objectValuesString(item)?.toString();
        if (value.toLowerCase().includes(this.term.toLowerCase())) {
          results.push(item);
        }
      }
      this.results = results;
    } else {
      this.results = structuredClone(this.items);
    }
    this.cdr.detectChanges();
  }

  select(item: any, refresh = false) {
    if (this.type === 'one') {
      this.term = this.bindLabel
        ? item[this.bindLabel]
        : this.objectValuesString(item);
      if (this.bindValue) {
        this.valueId = item[this.bindValue];
        this.valueIdChange.emit(this.valueId);
      }
      this.value = item;
      this.valueMap = {
        [item.id]: true,
      };
      this.valueChange.emit(this.value);
    } else if (this.type === 'multiselect') {
      delete this.term;
      if (!this.value) {
        this.value = [];
      }
      if (!this.valueId) {
        this.valueId = [];
      }
      if (this.bindValue) {
        this.valueId.push(item[this.bindValue]);
        if (refresh) {
          this.valueIdChange.emit(this.valueId);
        }
      }
      this.value.push(item);
      this.valueMap[item.id] = true;
      if (refresh) {
        this.valueChange.emit(this.value);
      }
    }
    this.cdr.detectChanges();
  }

  remove(item: any, refresh = true) {
    console.log('remove', item, this.value);
    if (this.type === 'one') {
      this.value = undefined;
      this.valueId = undefined;
      this.valueChange.emit(this.value);
      this.valueIdChange.emit(this.valueId);
    } else if (this.type === 'multiselect') {
      this.value = this.value.filter((i: any) => {
        if (this.bindValue) {
          return i[this.bindValue] !== item[this.bindValue];
        }
        return i !== item;
      });
      if (this.bindValue) {
        this.valueId = this.value.filter(
          (i: any) => i !== (this.bindValue ? item[this.bindValue] : item)
        );
        delete this.valueMap[this.bindValue ? item[this.bindValue] : item];
        if (refresh) {
          this.valueIdChange.emit(this.valueId);
        }
      }
      if (refresh) {
        this.valueChange.emit(this.value);
      }
    }
    this.cdr.detectChanges();
  }

  dismissMenu() {
    console.log('dismiss');
    this.showDropdown = false;
    this.cdr.detectChanges();

    if (this.valueId) {
      this.valueIdChange.emit(this.valueId);
    }

    this.valueChange.emit(this.value);
  }

  objectValuesString(obj: any) {
    if (typeof obj === 'string') return obj;

    if (this.lookupMap?.[obj.id]) {
      if (this.lookupMap?.[obj.id]?.name) {
        return this.lookupMap?.[obj.id]?.name;
      }
      return (
        this.lookupMap?.[obj.id]?.firstName +
        ' ' +
        this.lookupMap?.[obj.id]?.lastName
      );
    }

    if (this.bindLabel) return obj[this.bindLabel];

    return Object.entries(obj)
      .filter(x => x[0] !== 'id')
      .map(x => x[1])
      .join(' ');
  }

  inputClicked() {
    console.log('input clicked');
    this.showDropdown = true;
    this.cdr.detectChanges();
  }

  showDropdownClicked(newValue: boolean, source?: string) {
    console.log('show dropdown clicked', newValue, source);
    this.showDropdown = newValue;
    this.cdr.detectChanges();
  }

  selectAll() {
    if (this.results) {
      for (const item of this.results) {
        if (!this.valueMap[item.id]) {
          this.select(item, false);
        }
      }
    }
    this.cdr.detectChanges();
  }
  deselectAll() {
    if (this.results) {
      for (const item of this.results) {
        this.remove(item, false);
      }
    }
    this.cdr.detectChanges();
  }
}
