<ng-container *ngIf="keys">
  @if (data$ | async; as state) {
    <div class="card">
      <div class="card-body">
        @if (state.payouts; as data) {
          <!-- <div *ngIf="agents.error">
      <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      <h3 class="my-4">An error occurred</h3>
      <p>{{ agents.error }}</p>
    </div> -->

          <div class="overflow-x-auto" #container *ngIf="data">
            <table class="table table-sm table-pin-rows table-pin-cols" #table>
              <thead>
                <tr>
                  <!-- <th>Extension</th>
              <th>Virtual Agents</th> -->
                  <th>
                    <div class="flex items-center gap-2">
                      <div class="grow" (click)="toggleSort('agent.firstName')">
                        Agent
                        <fa-icon
                          *ngIf="filter['order']?.field === 'agent.firstName'"
                          class="ml-2"
                          [icon]="
                            filter['order']?.direction === 'asc'
                              ? faArrowUp
                              : faArrowDown
                          "></fa-icon>
                      </div>

                      <button
                        *ngIf="showArrows"
                        class="btn btn-sm btn-circle btn-outline"
                        (click)="scrollLeft()">
                        <fa-icon [icon]="faChevronLeft"></fa-icon>
                      </button>
                    </div>
                  </th>
                  <td>Agent NPN</td>
                  <td *ngIf="showAllProfiles">Agency</td>
                  <td (click)="toggleSort('consumer.firstName')">
                    Consumer
                    <fa-icon
                      *ngIf="filter['order']?.field === 'consumer.firstName'"
                      class="ml-2"
                      [icon]="
                        filter['order']?.direction === 'asc'
                          ? faArrowUp
                          : faArrowDown
                      "></fa-icon>
                  </td>
                  <td>Policy #</td>
                  <td (click)="toggleSort('cycle.name')">
                    Statement Date
                    <fa-icon
                      *ngIf="filter['order']?.field === 'cycle.name'"
                      class="ml-2"
                      [icon]="
                        filter['order']?.direction === 'asc'
                          ? faArrowUp
                          : faArrowDown
                      "></fa-icon>
                  </td>
                  <td (click)="toggleSort('carrier.id')">
                    Carrier
                    <fa-icon
                      *ngIf="filter['order']?.field === 'carrier.id'"
                      class="ml-2"
                      [icon]="
                        filter['order']?.direction === 'asc'
                          ? faArrowUp
                          : faArrowDown
                      "></fa-icon>
                  </td>
                  <ng-container *ngFor="let k of keys">
                    <td (click)="toggleSort(k.keyDot)" *ngIf="k.primitive">
                      {{ k.title }}
                      <fa-icon
                        *ngIf="filter['order']?.field === k.keyDot"
                        class="ml-2"
                        [icon]="
                          filter['order']?.direction === 'asc'
                            ? faArrowUp
                            : faArrowDown
                        "></fa-icon>
                    </td>
                  </ng-container>
                  <td (click)="toggleSort('payout.personal')">
                    <!-- @if (filter['agency-filter-type'] === 'MY_PAYOUTS') {
                  Net Commission by Agent
                } @else if (filter['agency-filter-type'] === 'BUSINESS_WRITTEN') {
                  How much agent made
                } @else {
                  Payouts Personal
                } -->
                    Net Commission
                    <fa-icon
                      *ngIf="filter['order']?.field === 'payout.personal'"
                      class="ml-2"
                      [icon]="
                        filter['order']?.direction === 'asc'
                          ? faArrowUp
                          : faArrowDown
                      "></fa-icon>
                  </td>
                  <td (click)="toggleSort('identifiedResult.operationMessage')">
                    Identified Payout
                    <fa-icon
                      *ngIf="
                        filter['order']?.field ===
                        'identifiedResult.operationMessage'
                      "
                      class="ml-2"
                      [icon]="
                        filter['order']?.direction === 'asc'
                          ? faArrowUp
                          : faArrowDown
                      "></fa-icon>
                  </td>
                  <th>
                    <div class="flex items-center gap-2">
                      <button
                        *ngIf="showArrows"
                        class="btn btn-sm btn-circle btn-outline"
                        (click)="scrollRight()">
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                      </button>
                      <span class="grow"> Actions </span>
                    </div>
                  </th>
                  <!-- <th (click)="toggleSort('teamLeader')">
              Team Leader
              <fa-icon
                *ngIf="filter['order']?.key === 'teamLeader'"
                class="ml-2"
                [icon]="
                  filter['order']?.direction === 'asc' ? faArrowUp : faArrowDown
                "></fa-icon>
            </th> -->
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let d of data">
                  <tr>
                    <th>
                      <div
                        class="flex flex-row gap-2 items-center rounded-full pr-2">
                        <div
                          class="md:flex hidden avatar"
                          *ngIf="d.agent && d.agent.profileImage">
                          <div class="w-10 rounded-full">
                            <img [src]="d.agent.profileImage" />
                          </div>
                        </div>
                        <div
                          class="md:flex hidden w-10 h-10 items-center justify-center shrink-0"
                          *ngIf="!d?.agent?.profileImage">
                          <fa-icon [icon]="faUserTie"></fa-icon>
                        </div>
                        <div>
                          <!-- <p>{{ d.agent?.firstName }} {{ d.agent?.lastName }}</p> -->
                          @if (d.agent?.firstName === d.agent?.lastName) {
                            <p>{{ d.agent?.firstName }}</p>
                          } @else {
                            <p>
                              {{ d.agent?.firstName }} {{ d.agent?.lastName }}
                            </p>
                          }
                        </div>
                      </div>
                    </th>
                    <td>{{ d?.agent?.npn }}</td>
                    <td *ngIf="showAllProfiles">
                      @if (d?.agency?.id) {
                        <app-agency-tag
                          [agencyId]="d.agency?.id"></app-agency-tag>
                      }
                    </td>
                    <td>
                      @if (d?.consumer?.firstName === d?.consumer?.lastName) {
                        {{ d?.consumer?.firstName }}
                      } @else {
                        {{ d?.consumer?.firstName }} {{ d?.consumer?.lastName }}
                      }
                    </td>
                    <!-- <td>{{ getId(d?.id) }}</td> -->
                    <td>{{ d?.policyId }}</td>
                    <td>
                      @if (d.cycle?.name) {
                        <span class="badge badge-outline h-auto rounded-xl">{{
                          d.cycle?.name
                        }}</span>
                      }
                    </td>
                    <td>
                      @if (d.carrier && d.carrier.id) {
                        <div
                          class="flex flex-row gap-2 items-center rounded-full pr-2"
                          *ngIf="getKey(carrierMap, d.carrier.id); let carrier">
                          <div
                            class="w-10 object-contain"
                            *ngIf="carrier?.profileImage">
                            <img [src]="carrier.profileImage" />
                          </div>
                          <div
                            class="w-10 h-10 flex items-center justify-center shrink-0"
                            *ngIf="!carrier?.profileImage">
                            <fa-icon [icon]="faUserTie"></fa-icon>
                          </div>
                          <div>
                            <p>{{ carrier?.name }}</p>
                          </div>
                        </div>
                      }
                    </td>
                    <ng-container *ngFor="let k of keys">
                      <td *ngIf="k.primitive">
                        <span *ngIf="k.key; else noKey">
                          {{ getValue(d, k) }}
                        </span>
                        <ng-template #noKey>
                          @if (d) {
                            {{ getKey(d, k.id) }}
                          }
                        </ng-template>
                      </td>
                    </ng-container>
                    <td>
                      {{ d.payout?.personal | currency }}
                    </td>
                    <td>
                      {{ getCommissionIdentifiedText(d) }}
                    </td>
                    <th>
                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="btn btn-circle btn-ghost"
                          (click)="showPayout(d)">
                          <fa-icon [icon]="faEye"></fa-icon>
                        </button>
                      </div>
                    </th>
                    <!-- <td>
                <div class="flex flex-row gap-2 items-center rounded-full pr-2">
                  <div class="avatar" *ngIf="d?.teamLeader?.profileImage">
                    <div class="w-10 rounded-full">
                      <img [src]="d.teamLeader.profileImage" />
                    </div>
                  </div>
                  <div
                    class="w-10 h-10 flex items-center justify-center shrink-0"
                    *ngIf="!d?.profileImage">
                    <fa-icon [icon]="faUserTie"></fa-icon>
                  </div>
                  <div>
                    <p>
                      {{ d.teamLeader?.firstName }} {{ d.teamLeader?.lastName }}
                    </p>
                  </div>
                </div>
              </td> -->
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        }
        <div
          *ngIf="state.loading || !state.payouts"
          class="skeleton h-16 w-full"></div>
      </div>
    </div>
  } @else {
    <div class="skeleton h-16 w-full"></div>
  }
</ng-container>

<!-- <app-payouts-detail-modal #payoutsDetailModal></app-payouts-detail-modal> -->
