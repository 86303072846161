import {
  IconDefinition,
  faAmbulance,
  faArrowRight,
  faBox,
  faBrain,
  faBus,
  faCapsules,
  faCarCrash,
  faCircle,
  faCirclePlus,
  faDollarSign,
  faEar,
  faEye,
  faFaceExplode,
  faGaugeMax,
  faGift,
  faHeadSideMedical,
  faHeartbeat,
  faHospital,
  faHouse,
  faJoint,
  faLungs,
  faOctagon,
  faPaw,
  faPills,
  faQuestion,
  faReceipt,
  faRobot,
  faScalpel,
  faSocks,
  faSpa,
  faStarOfLife,
  faStethoscope,
  faStretcher,
  faSuitcaseMedical,
  faTimes,
  faTooth,
  faTruckMedical,
  faUserDoctor,
  faUserNurse,
  faVials,
  faWallet,
  faWheelchair,
} from '@fortawesome/pro-regular-svg-icons';

export const iconClassMap: { [key: string]: IconDefinition } = {
  'far fa-times': faTimes,

  'far fa-ambulance': faAmbulance,
  'far fa-stethoscope': faStethoscope,
  'far fa-bus': faBus,
  'far fa-box': faBox,

  'far fa-dollar-sign': faDollarSign,
  'far fa-capsules': faCapsules,
  'far fa-arrow-right': faArrowRight,
  'far fa-wallet': faWallet,
  'far fa-pills': faPills,
  'far fa-octagon': faOctagon,
  'far fa-face-explode': faFaceExplode,
  'far fa-gift': faGift,
  'far fa-hospital': faHospital,
  'far fa-head-side-medical': faHeadSideMedical,
  'far fa-user-nurse': faUserNurse,
  'far fa-suitcase-medical': faSuitcaseMedical,
  'far fa-circle-plus': faCirclePlus,
  'far fa-stretcher': faStretcher,
  'far fa-tooth': faTooth,
  'far fa-vials': faVials,
  'far fa-user-doctor': faUserDoctor,
  'far fa-truck-medical': faTruckMedical,
  'far fa-ear': faEar,
  'far fa-house': faHouse,
  'far fa-question': faQuestion,
  'far fa-brain': faBrain,
  'far fa-receipt': faReceipt,
  'far fa-star-of-life': faStarOfLife,
  'far fa-scalpel': faScalpel,
  'far fa-joint': faJoint,
  'far fa-socks': faSocks,
  'far fa-spa': faSpa,
  'far fa-robot': faRobot,
  'far fa-lungs': faLungs,
  'far fa-gauge-max': faGaugeMax,
  'far fa-eye': faEye,
  'far fa-circle': faCircle,

  'fas fa-heartbeat': faHeartbeat,
  'fas fa-tooth': faTooth,
  'fas fa-eye': faEye,
  'fas fa-wheelchair': faWheelchair,
  'fas fa-car-crash': faCarCrash,
  'fas fa-paw': faPaw,
};
