<!-- (mouseenter)="showDropdown = true"
(mouseleave)="showDropdown = false" -->
<div [class.flex]="small" class="gap-2 w-full">
  <div class="mb-4 min-w-16 shrink-0 grow relative" [class.w-full]="!small">
    <div
      class="form-control"
      [class.w-full]="!small"
      (click)="showDropdownClicked(true, 'form-control')">
      <label class="label" *ngIf="title" [class.smallTitle]="smallTitle">
        <span class="label-text">
          <fa-icon *ngIf="icon" class="mr-2" [icon]="icon"></fa-icon>
          <i *ngIf="iconClass" class="mr-2" [class]="iconClass"></i>
          {{ title }}
        </span>
      </label>
      <div
        class="input input-bordered flex h-auto p-2 gap-2 z-40"
        *ngIf="small">
        <div
          *ngIf="!hideSelected && type === 'multiselect'"
          class="flex flex-wrap gap-2 items-center">
          <div *ngFor="let v of value">
            <span class="badge badge-outline h-auto rounded-box">
              <span class="badge-content" *ngIf="bindValue">{{
                objectValuesString(v)
              }}</span>
              <span class="badge-content" *ngIf="!bindValue">{{
                objectValuesString(v)
              }}</span>
              <button
                type="button"
                class="btn btn-ghost btn-xs rounded-btn"
                (click)="remove(v, !showDropdown)">
                <fa-icon [icon]="faTimes"></fa-icon>
              </button>
            </span>
          </div>
        </div>

        <input
          [class.z-30]="showDropdown"
          [class.z-10]="!showDropdown"
          [class.input-sm]="small"
          [class.w-full]="!small"
          [id]="id"
          type="text"
          [placeholder]="placeholder"
          [(ngModel)]="term"
          (keyup)="search()"
          (focus)="inputClicked()"
          class="input border-none min-w-16 shrink-0 grow" />
      </div>
      <input
        *ngIf="!small"
        [class.z-30]="showDropdown"
        [class.z-10]="!showDropdown"
        [class.input-sm]="small"
        [class.w-full]="!small"
        [id]="id"
        type="text"
        [placeholder]="placeholder"
        [(ngModel)]="term"
        (keyup)="search()"
        (focus)="inputClicked()"
        class="input input-bordered" />
    </div>
    <div
      class="fixed top-0 left-0 right-0 bottom-0 z-[25]"
      (click)="showDropdownClicked(false, 'overlay'); valueChange.emit(value)"
      *ngIf="!customResults && showDropdown"></div>
    <ul
      *ngIf="!customResults && showDropdown"
      class="absolute dropdown-content z-[29] menu p-2 shadow bg-base-100 rounded-box w-full"
      [class.dropdown-open]="term && showDropdown">
      <li class="p-2" *ngIf="loading"><app-loading></app-loading></li>
      <ng-container *ngIf="!loading">
        <li class="p-2" *ngIf="!results">Enter your search term</li>
        <li class="p-2" *ngIf="results?.length === 0">
          No results for {{ term }}
        </li>
        @if (showSelectAll) {
          <li class="px-2 pt-2 flex md:flex-row gap-2">
            <button class="btn btn-sm btn-outline" (click)="selectAll()">
              <fa-icon class="mr-2" [icon]="faCheckCircle"></fa-icon> Select All
            </button>
            <button class="btn btn-sm btn-outline" (click)="deselectAll()">
              <fa-icon class="mr-2" [icon]="faCircle"></fa-icon> Deselect All
            </button>
          </li>
        }
        <li
          class="p-2"
          *ngFor="let r of results"
          (click)="valueMap[r.id] ? remove(r, false) : select(r, false)">
          <a>
            <fa-icon
              class="mr-2"
              [icon]="valueMap[r.id] ? faCheckCircle : faCircle"></fa-icon
            >{{ objectValuesString(r) }}</a
          >
        </li>
      </ng-container>
    </ul>
  </div>

  <div
    *ngIf="showDropdown"
    class="absolute z-0 top-0 left-0 bottom-0 right-0"
    (click)="dismissMenu()"></div>

  <div
    *ngIf="!small && !hideSelected && type === 'multiselect'"
    class="flex flex-wrap"
    [class.mt-9]="small">
    <div *ngFor="let v of value">
      <span
        class="badge badge-outline badge-primary mr-2 mb-2 h-auto rounded-box">
        <span class="badge-content" *ngIf="bindValue">{{
          objectValuesString(v)
        }}</span>
        <span class="badge-content" *ngIf="!bindValue">{{
          objectValuesString(v)
        }}</span>
        <button
          type="button"
          class="btn btn-ghost btn-xs rounded-btn"
          (click)="remove(v)">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </span>
    </div>
  </div>
</div>
