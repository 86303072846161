<!-- <app-ui-input
  *ngIf="search"
  id="searchTerm"
  type="text"
  (ngModelChange)="textFieldChange($event)"
  [(ngModel)]="searchTerm"
  ngDefaultControl
  label="Bitte Suchbegriff eingeben"
  icon="fal fa-search">
</app-ui-input> -->

<!-- [class.flex]="fullWidth" -->
<div
  class="flex w-full"
  [class.flex-wrap]="!scroll"
  [class.overflow-x-auto]="scroll"
  [class.big]="big"
  [class.fit]="fit"
  [class.-mx-2]="fullWidth"
  [class.justify-center]="center"
  [class.center]="center">
  <button
    type="button"
    *ngFor="let i of items; let j = index"
    class="btn m-1 max-w-full h-auto"
    [class.grow]="spread"
    [class.btn-primary]="i?.active"
    [class.btn-outline]="!i?.active"
    [class.w-full]="fullWidth"
    [class.mx-2]="fullWidth"
    [class.times]="
      !extraSmall && !small && (showTimesAlways || showTimesOnActive)
    "
    [class.btn-sm]="small"
    [class.extra-small]="extraSmall"
    [class.big]="big"
    [class.invalid]="error"
    [class.highlight]="highlightMap && highlightMap[j]"
    [class.active]="i?.active"
    [class.ignoreClick]="ignoreClick"
    (click)="select(i)">
    <fa-icon
      *ngIf="i && i.iconClass && iconClassMap[i.iconClass]"
      [icon]="iconClassMap[i.iconClass]"
      [class.text-gradient]="gradientIcon && !i?.active"></fa-icon>
    <fa-icon
      *ngIf="i && i.icon"
      [icon]="i.icon"
      [class.text-gradient]="gradientIcon && !i?.active"></fa-icon>
    <div
      [class.text-center]="big"
      class="flex flex-row items-center justify-between grow">
      <span *ngIf="!bindLabel">{{ i }}</span>
      <span *ngIf="!asInnerHtml && bindLabel">{{ i[bindLabel] }}</span>
      <span *ngIf="asInnerHtml && bindLabel" [innerHtml]="i[bindLabel]"></span>
      <p
        class="text-xs text-center pb-0 hyphens-auto whitespace-break-spaces break-words"
        *ngIf="i.subTitle && !small && !extraSmall">
        {{ i.subTitle }}
      </p>

      <div>
        <kbd
          class="ml-2 kbd"
          *ngIf="
            allowKeyboard && i[this.bindValue] && itemKeyMap[i[this.bindValue]]
          ">
          {{ itemKeyMap[i[this.bindValue]] }}
        </kbd>
      </div>
    </div>
    <!-- <span *ngIf="mwd">&nbsp;(m/w/d)</span> -->
    <i
      *ngIf="showTimesAlways || (showTimesOnActive && i.active)"
      class="fal fa-times"></i>
    <!-- <i *ngIf="showTimesOnActive && i.active" class="fal fa-times" (click)="remove(i)"></i> -->
  </button>
</div>
<div class="flex" [class.justify-center]="center">
  <label *ngIf="error" [class.text-center]="center" class="text-error">{{
    error
  }}</label>
</div>
