import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { v4 as uuid } from 'uuid';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, NgIf } from '@angular/common';
import { iconClassMap } from 'src/app/shared/models/iconClassMap';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FaIconComponent],
})
export class TagsComponent implements OnInit, OnChanges {
  id = uuid();
  @Input() scroll = false;
  @Input() search = false;
  @Input() fit = false;
  @Input() radio = false;
  @Input() deselectable = false;
  @Input() small = false;
  @Input() extraSmall = false;
  @Input() big = false;
  @Input() center = false;
  @Input() gradientIcon = false;
  @Input() fullWidth = false;
  @Input() ignoreClick = false;

  @Input() mwd = false;
  @Input() skipClear = false;

  @Input() asInnerHtml = false;

  @Input() items?: any[];
  @Input() error: any;
  @Input() bindValue = 'id';
  @Input() bindLabel: any = 'title';
  @Input() value?: any;
  @Input() showTimesOnActive = false;
  @Input() showTimesAlways = false;
  @Input() maxCheckbox?: number;

  @Input() allowKeyboard = false;
  @Input() spread = false;

  @Input() updateView?: EventEmitter<void>;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedValue: EventEmitter<any> = new EventEmitter<any>();
  public valueIsItem = false;

  public searchTerm?: string;
  public highlightMap: { [key: string]: any } = {};

  iconClassMap = iconClassMap;

  possibleKeyMap: { [key: string]: any } = {};
  itemKeyMap: { [key: string]: string } = {};

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.allowKeyboard) {
      console.log('event', event.key, event.code);
      if (this.possibleKeyMap[event.key.toLowerCase()]) {
        this.select(this.possibleKeyMap[event.key.toLowerCase()]);
      }
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    if (this.updateView) {
      this.updateView.subscribe(() => this.cdr.detectChanges());
    }

    if (!this.items) {
      this.items = this.value;
      this.valueIsItem = true;
    }
    this.items?.forEach(x =>
      !this.valueIsItem && (this.bindValue != '' || this.bindLabel != '')
        ? (x.active = this.isActive(x))
        : null
    );

    if (this.allowKeyboard && this.items) {
      for (const i of this.items) {
        const firstLetter = i[this.bindLabel][0].toLowerCase();

        let found: string | null = null;
        // let maxTries = 10;
        // let lastLetter;

        if (!this.possibleKeyMap[firstLetter]) {
          found = firstLetter;
          // lastLetter = firstLetter;
        }

        if (!found) {
          this.allowKeyboard = false;
        }

        // while (!found && maxTries > 0) {
        //   --maxTries;

        //   let nextLetter: string =
        //     allPossibleKeys[allPossibleKeys.indexOf(lastLetter) + 1];

        //   if (!nextLetter) {
        //     nextLetter = allPossibleKeys[0];
        //   }

        //   if (!this.possibleKeyMap[nextLetter]) {
        //     this.possibleKeyMap[nextLetter] = i;
        //     found = nextLetter;
        //   } else {
        //     lastLetter = nextLetter;
        //   }
        // }

        if (found) {
          this.possibleKeyMap[found] = i;
          this.itemKeyMap[i[this.bindValue]] = found;
        }
      }
      console.log('this.possibleKeyMap', this.possibleKeyMap);
    }
  }

  ngOnChanges() {
    this.items?.forEach(x =>
      !this.valueIsItem && (this.bindValue != '' || this.bindLabel != '')
        ? (x.active = this.isActive(x))
        : null
    );
  }

  isArray = (i: any) => Array.isArray(i);

  select(value: any) {
    if (!this.ignoreClick) {
      if (this.radio) {
        if (
          this.deselectable &&
          this.value === (this.bindValue ? value[this.bindValue] : value)
        ) {
          delete this.value;
        } else {
          this.value = this.bindValue ? value[this.bindValue] : value;
        }
      } else {
        // console.log(this.bindValue, this.value, value);
        if (!this.value || !(this.value instanceof Array)) {
          this.value = [];
        }
        if (
          !value.active &&
          !(
            this.valueIsItem &&
            this.value.find(
              (x: any) => x === (this.bindValue ? value[this.bindValue] : value)
            )
          )
        ) {
          if (this.maxCheckbox && this.maxCheckbox <= this.value.length) {
            // this.value.shift();
            // this.value.pop();
            this.toastService.error(
              `There can only be ${this.maxCheckbox} options selected.`
            );
            // this.toastService.error(
            //   $localize`:@@onlyXoptionsCanBeSelectedLine1:Es können nur ` +
            //     this.maxCheckbox +
            //     $localize`:@@onlyXoptionsCanBeSelectedLine2:Optionen ausgewählt werden.`
            // );
          } else {
            this.value.push(this.bindValue ? value[this.bindValue] : value);
          }
        } else {
          this.value = this.value.filter(
            (x: any) => x != (this.bindValue ? value[this.bindValue] : value)
          );
        }
      }
      if (this.valueIsItem) {
        this.items = this.items?.filter(x => x != value);
        this.value = this.items;
      }
      this.clickedValue.emit(value);
      this.valueChange.emit(this.value);
      this.items?.forEach(x =>
        !this.valueIsItem ? (x.active = this.isActive(x)) : null
      );
    }
    this.cdr.detectChanges();
  }

  textFieldChange(event: any) {
    this.highlightMap = {};
    if (event.length > 0) {
      for (const i in this.items) {
        if (
          (this.items[i as any][this.bindLabel]
            ?.toLowerCase()
            ?.indexOf(event.toLowerCase()) ?? -1) > -1
        ) {
          this.highlightMap[i] = true;
        }
      }
    }
  }

  // remove(i) {
  //   console.log('remove', i)
  //   i.active = false
  //   this.value = this.value.filter((x:any) => x != i.id)
  //   this.valueChange.emit(this.value)
  //   console.log(this.value)
  // }

  isActive(value: any) {
    if (this.radio) {
      return this.value == (this.bindValue ? value[this.bindValue] : value);
    }
    return Array.isArray(this.value)
      ? this.value.indexOf(this.bindValue ? value[this.bindValue] : value) > -1
      : false;
  }
}
